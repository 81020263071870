import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withStyles, WithStyles, Theme, createStyles } from '@material-ui/core/styles';
import Nav from 'header/Nav';
import Footer from 'footer/Bar';

const styles = (theme: Theme) => createStyles({
  errorContainer: {
    height: '50vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
});

type Props = RouteComponentProps & WithStyles<typeof styles>;

class Chrome extends Component<Props> {
  render() {
    const { classes, match } = this.props;
    const errorMessage = match.params ? match.params.message : '';

    return (
      <>
        <Nav />
        <div className={classes.errorContainer}>
          <h1>{errorMessage}</h1>
        </div>
        <footer>
          <Footer />
        </footer>
      </>
    );
  }
}

export default withStyles(styles)(withRouter(Chrome));
