export const formatPhone = (phone: any) => {
  const phoneNumber = phone.replace(/[^\d]/g, "");

  if (phoneNumber === '') return null;

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};
