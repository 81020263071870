import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { regularUserManager } from './userManager';

const styles = theme => ({
  loginButton: {
    width: '100%',
    textTransform: 'none',
    minHeight: '32px',
    height: '32px',
    fontSize: '12px',
  },
});

class OauthLoginButton extends Component {

  login = () => {
    regularUserManager.signinRedirect();
  }

  render() {
    const { classes } = this.props;

    return (
      <Button variant="contained" color="secondary" className={classes.loginButton} onClick={this.login}>
        Login
      </Button>
    );
  }
}

export default withStyles(styles)(OauthLoginButton);
