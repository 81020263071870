import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Avatar,
  IconButton,
  InputBase,
} from '@material-ui/core';
import {
  Mail,
  Comment as PostIcon,
  Event as TaskIcon,
  Beenhere,
  Reply,
  Close,
} from '@material-ui/icons/';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SimpleDialog from 'components/SimpleDialog';
import classNames from 'classnames';
import moment from 'moment';
import { getChitChatTypeKey } from 'common/chitChatTypes';
import { saveChitChatComment, fetchChitChat } from 'actions/chitchat';
import { connect } from 'react-redux';
import Fade from '@material-ui/core/Fade';

const styles = theme => ({
  thread: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
    border: `1px solid ${theme.palette.background.border}`,
    borderRadius: 4,
    background: theme.palette.background.paper,
    padding: '10px 25px',
  },
  top: {
    display: 'flex',
  },
  message: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 24,
  },
  messageIcon: {
    alignItems: 'center',
    marginTop: 2,
    marginRight: 16,
  },
  icon: {
    borderRadius: 5,
    width: 32,
    height: 32,
    fontSize: 17,
  },
  messageMainSection: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    margin: '0 8px 8px 0',
    fontSize: 14,
    maxWidth: '92%',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  messageSender: {
    color: theme.palette.light.purple1,
  },
  messageContent: {
    whiteSpace: 'initial',
    overflowWrap: 'break-word',
  },
  messageTime: {
    color: theme.palette.text.secondary,
    minWidth: 108,
    fontSize: 12,
  },
  messageDropDown: {
    paddingLeft: 16,
  },
  magenta: {
    backgroundColor: theme.palette.common.magenta,
  },
  bluegrey: {
    backgroundColor: theme.palette.common.bluegrey,
  },
  red: {
    backgroundColor: theme.palette.common.red,
  },
  blue: {
    backgroundColor: theme.palette.blue.blue3,
  },
  hr: {
    margin: '4px 0',
    borderTop: `1px solid ${theme.palette.light.darkgrey}`,
  },
  commentSection: {
    display: 'flex',
    flexDirection: 'column',
    margin: '8px 60px 0',
  },
  comment: {
    display: 'flex',
    padding: 8,
  },
  inlineReplyWrapper: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: -11,
  },
  inlineReply: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `1px solid ${theme.palette.light.darkgrey}`,
    borderRadius: 4,
    backgroundColor: theme.palette.background.paper,
  },
  replyField: {
    flex: '1 1 auto',
    padding: '6px 12px',
    maxWidth: '91%',
    '& .fr-view': {
      whiteSpace: 'normal',
    },
    '& .fr-wrapper.show-placeholder .fr-placeholder': {
      marginTop: '14px !important',
    },
  },
  replyInput: {
    width: '100%',
  },
  buttonContainer: {
    backgroundColor: theme.palette.background.paper,
    paddingRight: 12,
  },
  sendButton: {
    padding: '0 20px',
  },
  replyPrompt: {
    display: 'flex',
    flex: 1,
    cursor: 'pointer',
    alignItems: 'center',
    margin: '-30px 0 0 0',
    color: theme.palette.grey[500],
    width: 40,
    marginLeft: 'auto',
  },
  iconButton: {
    padding: 4,
    margin: '0 4px',
    color: theme.palette.grey[500],
  },
});

class Thread extends Component {

  state = {
    isReplying: false,
    reply: {},
  };

  errorDialog = React.createRef();

  getAvatar = chitChatType => {
    const { classes } = this.props;

    const icons = {
      'post': <PostIcon />,
      'email': <Mail />,
      'systemGeneratedAction': <Beenhere />,
      'userCreatedAction': <Beenhere />,
      'task': <TaskIcon />,
    };

    const colors = {
      'post': 'magenta',
      'email': 'bluegrey',
      'systemGeneratedAction': 'red',
      'userCreatedAction': 'red',
      'task': 'blue',
    };

    const color = colors[chitChatType] || 'magenta';
    const icon = icons[chitChatType] || null;

    return (
      <div className={classes.messageIcon}>
        <Avatar className={classNames(classes.icon, classes[color])}>
          {icon}
        </Avatar>
      </div>
    );
  }

  getSubjectLine = chitChatType => {
    const { classes, thread: { baseMessage } } = this.props;

    const subjectLines = {
      'post': baseMessage.from,
      'email': baseMessage.from + ' | Email | ' + baseMessage.subject,
      'systemGeneratedAction': 'System Generated Action',
      'userCreatedAction': baseMessage.from + ' | Action | ' + baseMessage.subject,
      'task': baseMessage.from + ' | Task Completed | ' + baseMessage.subject,
    };

    return <div className={classes.messageSender}>{subjectLines[chitChatType] || baseMessage.from}</div>;
  }

  onReply = () => {
    this.setState({ isReplying: true });
  }

  onCancelReply = () => {
    this.setState({ isReplying: false, reply: {} });
  }

  onSendComment = () => {
    const { reply } = this.state;
    const { thread, saveChitChatComment } = this.props;

    if (!!reply.message) {
      const comment = {
        subject: 'Reply',
        content: reply.message,
        chitChatThreadId: thread.id,
        time: moment().format(),
      };

      saveChitChatComment(thread.relationshipId, comment).then(() => {
        this.setState({ isReplying: false, reply: {} });
        this.props.onCommentSent(); //probably refresh chitchat timeline
      }).catch(() => {
        this.errorDialog.current.open('Something went wrong. Please try again.');
      });
    } else {
      this.errorDialog.current.open('Please include a message.');
    }
  }

  handleChange = event => {
    const { target: { value, name } } = event;
    const reply = {
      ...this.state.reply,
      [name]: value,
    };

    this.setState({ reply });
  }

  render() {
    const { classes, thread } = this.props;
    const { isReplying, reply } = this.state;
    const message = thread.baseMessage;
    const chitChatType = getChitChatTypeKey(thread.chitChatType);

    moment.locale('en', {
      calendar: {
        lastDay: 'LT, [Yesterday]',
        sameDay: 'LT, [Today]',
        lastWeek: 'llll',
        sameElse: 'llll',
      },
    });

    return (
      <div className={classes.thread}>
        <div className={classes.top}>
          {/* {this.getAvatar(chitChatType)} */}
          <div className={classes.messageMainSection}>
            {this.getSubjectLine(chitChatType)}
            {message && <>
              <div className={classes.messageContent}>
                <p dangerouslySetInnerHTML={{ __html: message.content }}></p>
              </div>
            </>
            }
          </div>
          <div className={classes.messageTime}>
            {message && moment(message.time).calendar()}
          </div>
        </div>

        <div className={classes.commentSection}>
          {thread.comments.length > 0 && thread.comments.map(comment =>
            <div key={comment.id} className={classes.hr}>
              <div className={classes.comment}>

                <div className={classes.messageMainSection}>
                  <div className={classes.messageSender}>{comment.from || 'Reply'}</div>
                  <div className={classes.messageContent}>
                    <p dangerouslySetInnerHTML={{ __html: comment.content }}></p>
                  </div>
                </div>

                <div className={classes.messageTime}>
                  {moment(comment.time).calendar()}
                </div>
              </div>
            </div>
          )}

        </div>

        {isReplying ?
          <Fade in={isReplying}>
            <div className={classes.inlineReplyWrapper}>
              <div className={classes.inlineReply}>
                <div className={classes.replyField}>
                  <InputBase
                    name="message"
                    value={reply.message}
                    onChange={this.handleChange}
                    className={classes.replyInput}
                  />
                </div>
                <div className={classes.buttonContainer}>
                  <PrimaryButton
                    onClick={this.onSendComment}
                    className={classes.sendButton}
                  >
                    Send
                  </PrimaryButton>
                </div>
              </div>
              <IconButton
                aria-label="Cancel"
                onClick={this.onCancelReply}
                className={classes.iconButton}
              >
                <Close />
              </IconButton>
            </div>
          </Fade>
          :
          <div className={classes.replyPrompt} onClick={this.onReply}>
            <IconButton
              aria-label="Reply"
              className={classes.iconButton}
            >
              <Reply />
            </IconButton>
          </div>
        }

        {/* <div className={classes.hr}></div> */}
        <SimpleDialog onlyOkayButton={true} innerRef={this.errorDialog} />
      </div>
    );
  }
}

const mapDispatchToProps = {
  saveChitChatComment,
  fetchChitChat,
};

export default withStyles(styles)(connect(undefined, mapDispatchToProps)(Thread));

