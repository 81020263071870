import React, { useContext } from 'react';
import CompanyContext from 'company/CompanyContext';
import { formatPhone } from 'utilities/PhoneFormatter';

export default () => {
  const {
    company,
  } = useContext(CompanyContext);

  if (!company) {
    return (<></>);
  }

  return (
    <div style={{
      textAlign: 'center',
      padding: '30px',
      color: '#797979',
      fontSize: '18px',
    }}>
      {!!company.name &&
        <div>{company.name}</div>
      }
      {!!company.address &&
        <div>{company.address}</div>
      }
      {!!company.phone &&
        <div>{formatPhone(company.phone)}</div>
      }
      {!!company.email &&
        <div>{company.email}</div>
      }
      {!!company.webSite &&
        <div>{company.webSite}</div>
      }
    </div>
  );
};
