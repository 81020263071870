import React from 'react';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const styles = theme => ({
  button: {
    color: theme.palette.grey[800],
    borderColor: theme.palette.grey[900],
    borderRadius: 0,
    height: 48,
    width: 167,
    marginTop: 40,
  },
});

function ButtonAsLink(props) {
  return (<Link {...props} />);
}

function EventDetailsButton({ booking, classes }) {
  return (
    <Button variant="outlined" classes={{ root: classes.button }} component={ButtonAsLink} to={`/booking/${booking.id}`}>
      Event Details
    </Button>
  );
}

export default withStyles(styles)(EventDetailsButton);
