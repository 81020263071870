export const CATEREASE_CP_RECEIVED = 'CATEREASE_CP_RECEIVED';
export const RESET_CATEREASE_CP = 'RESET_CATEREASE_CP';

export const types = {
  CATEREASE_CP_RECEIVED,
  RESET_CATEREASE_CP,
};

export const CatereaseCpReceived = (catereaseCP: string) => ({
  type: CATEREASE_CP_RECEIVED,
  catereaseCP,
});

export const ResetCatereaseCp = () => ({
  type: RESET_CATEREASE_CP,
});

export type Action =
  ReturnType<typeof CatereaseCpReceived> |
  ReturnType<typeof ResetCatereaseCp>;

export type State = {
  catereaseCP: string;
}

const initialState: State = {
  catereaseCP: '',
};

export function caterease(state = initialState, action) {
  switch (action.type) {
    case types.CATEREASE_CP_RECEIVED: {
      const { catereaseCP } = action;

      return { ...state, catereaseCP };
    }
    case types.RESET_CATEREASE_CP: {
      return { ...state, catereaseCP: '' };
    }
    default: {
      return state;
    }
  }
}
