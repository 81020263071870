import callApi from 'common/callApi';
import { BOOKING_RECEIVED } from 'common/constants';

export const getEvents = ({ firstDate, skip = 0, pageSize = 2 }) =>
  dispatch => dispatch(callApi(`v1/Events/calendarEvent?firstDate=${firstDate}&skip=${skip}&pageSize=${pageSize}`))
    .then(result => result.json());

export const getBookings = ({ skip = 0, pageSize = 2, showCompleted = false } = {}) => {
  const filters = [
    { field: 'isConfirmed', value: true, operator: 'eq' },
    { field: 'status', value: 'Complete', operator: showCompleted ? 'eq' : 'neq' },
  ];

  const filtersToQueryString = encodeURIComponent(JSON.stringify(filters));

  return dispatch => dispatch(callApi(`v1/bookings?skip=${skip}&pageSize=${pageSize}&filters=${filtersToQueryString}`))
    .then(result => result.json());
};

export const getBooking = bookingId =>
  dispatch => dispatch(callApi(`v1/bookings/${bookingId}`))
    .then(result => result.json())
    .then(booking => {
      dispatch(bookingReceived(booking));

      return booking;
    });

export const confirmBooking = (booking: any, confirmedContact: string) =>
  dispatch => dispatch(callApi(
    `v1/bookings/${booking.id}/confirm/${confirmedContact}`,
    { body: null, contentType: null, method: 'POST' }
  ))
    .then(result => {
      if (result.status === 200) {
        const confirmedContactDate = new Date().toISOString();
        const updatedBooking = { ...booking, confirmedContact, confirmedContactDate };

        dispatch(bookingReceived(updatedBooking));
      }
    });

export const getImage = bookingId =>
  dispatch => dispatch(callApi(`v1/files/booking/${bookingId}/customerImage`))
    .then(result => {
      if (result.statusText !== 'No Content') {
        return result.json();
      } else { return result; }
    });

export const getFinancialDetails = bookingId =>
  dispatch => dispatch(callApi(`v1/bookings/${bookingId}/financialsummary`))
    .then(result => result.json());

export const makePayment = (payload: any) =>
  dispatch => dispatch(callApi(`BookingPayment/Authorize`,
    { body: payload, contentType: "application/json", method: 'POST' }))
    .then(result => result.json());

export const getLocation = bookingId =>
  dispatch => dispatch(callApi(`v1/bookings/${bookingId}/location`))
    .then(result => result.json());

export const getEventLocation = (bookingId, eventId) =>
  dispatch => dispatch(callApi(`v1/bookings/${bookingId}/event/${eventId}/location`))
    .then(result => result.json());

export const bookingReceived = booking => ({
  type: BOOKING_RECEIVED,
  booking,
});

export const getPaymentDetailsById = (paymentId: string) =>
  dispatch => dispatch(callApi(`BookingPayment/${paymentId}`,
    { contentType: "application/json", method: 'GET' }))
    .then(result => result.json());

export const makeCoverPaymentCall = (payload: any, id: string) =>
  dispatch => dispatch(callApi(`v1/payments/payment/event/${id}/location`, { body: payload, contentType: "application/json", method: 'POST' }))
    .then(result => result.json());

