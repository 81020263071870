import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Nav from './Nav';
import {
  getImage,
} from 'actions/events';
import { connect } from 'react-redux';

const styles = theme => ({
  container: {
    display: 'grid',
    flexDirection: 'column',
    backgroundColor: theme.palette.grey[800],
  },
  content: {
    display: 'flex',
    backgroundColor: theme.palette.grey[200],
  },
  imageColumn: {
    position: 'relative',
    width: '10%',
    '&:after': {
      content: '""',
      display: 'block',
      paddingBottom: '',
    },
  },
  imgContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // position: 'absolute',
    width: '100%',
    // height: '100%',
  },
  imageLogo: {
    minWidth: '100%',
    // maxWidth: '100%',
  },
});

class BookingHeader extends Component {
  state = {
    imgSrc: '',
  };

  componentDidMount() {
    this.loadImage();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.booking !== this.props.booking) {
      this.loadImage();
    }
  }

  loadImage = () => {
    if (!this.props.booking) {
      return;
    }
    this.props.getImage(this.props.booking.id).then(image => {
      if (image.content) {
        const imgSrc = `data:image/gif;base64, ${image.content}`;

        this.setState({ imgSrc });
      };
    });
  }

  render() {
    //TODO inject some parts of the booking into template
    const {
      classes,
    } = this.props;

    return (
      <div className={classes.container} >
        <Nav />
        {/* <div className={classes.content}>
          <div className={classes.imageColumn}>
            <div className={classes.imgContent}>
              {!!imgSrc &&
              <img src={imgSrc} alt="logo" className={classes.imageLogo}/>
              }
            </div>
          </div>
          {booking && <EventDetailsHeader bookingId={booking.id} />}
        </div> */}
      </div>
    );
  }
}

const mapDispatchToProps = {
  getImage,
};

const mapStateToProps = state => {
  const { booking } = state.api;

  return { booking };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BookingHeader));
