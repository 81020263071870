import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import reducers from 'reducers/rootReducer';
import { createTransform, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import createOidcMiddleware from 'redux-oidc';
import { regularUserManager } from 'auth/userManager';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

if (window.env.REACT_APP_INSIGHTS_ENABLED === 'true') {
  //because redux has some appInsights logging, it makes sense to set-up application insights before the store gets setup
  window.appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: process.env.REACT_APP_APPLICATION_INSIGHTS_KEY,
    },
  });

  window.appInsights.loadAppInsights();
  window.appInsights.trackEvent({ name: 'App loading' });
}
const oidcMiddleware = createOidcMiddleware(regularUserManager);

const loggerMiddleware = createLogger();

let composeEnhancers = compose;

if (process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
  });
}

const rehydrateTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState, key) => inboundState,
  // transform state being rehydrated
  (outboundState, key) => {
    if (key === 'api') {
      const { auth, salutations, states } = outboundState;

      return { auth, salutations, states };
    }

    return outboundState;
  },
  // define which reducers this transform gets called for.
  // { blacklist: [], whitelist: [] },
);

const persistanceConfig = {
  key: 'root',
  stateReconciler: autoMergeLevel2,
  storage,
  transforms: [rehydrateTransform],
  version: 1,
};

const persistedReducers = persistReducer(persistanceConfig, reducers);

const enhance = composeEnhancers(
  applyMiddleware(
    thunkMiddleware,
    loggerMiddleware,
    oidcMiddleware,
  )
);

export const store = createStore(
  persistedReducers,
  enhance
);

if (process.env.NODE_ENV !== 'production') {
  store.subscribe(() => {
    const state = store.getState();

    console.debug('store.subscribe()', state);
  });
}

export const persistor = persistStore(store);

window.purge = persistor.purge;
// persistor.purge();
