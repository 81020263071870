import React, { Component } from 'react';
import { getBookings } from 'actions/events';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import { withStyles } from '@material-ui/core/styles';
import ListItem from './ListItem';
import SearchPanel from './SearchPanel';
import { Button } from '@material-ui/core';
import { KeyboardBackspace } from '@material-ui/icons';

const styles = theme => ({
  container: {
    margin: '0 10%',
  },
  rotate: {
    transform: 'rotate(180deg)',
  },
  topRow: {
    paddingTop: 100,
    display: 'flex', //no idea why this need to be flex. The searchpanel is at the bottom without it
  },
  eventsContainer: {
    display: 'inline-block',
    marginLeft: -50,
    width: 1284,
  },
  searchContainer: {
    display: 'inline-block',
    marginLeft: 50,
    width: 238,
    flexGrow: 1,
  },
  currentPage: {
    color: theme.palette.blue.blue3,
    borderBottom: `solid 1px ${theme.palette.blue.blue3}`,
    borderRadius: 0,
  },
  pagerContainer: {
    borderTop: `solid 1px ${theme.palette.grey[400]}`,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 30,
    paddingBottom: 30,
  },
  pagerContent: {
    width: 735,
  },

});

const pageSize = 6;

class EventList extends Component {
  state = {
    bookings: [],
    meta: { total: 0 },
    skip: 0,
  };

  firstDate = DateTime.local();

  componentDidMount() {
    this.getBookings();
  }

  getBookings = (skip = 0) => {
    this.props.getBookings({
      firstDate: this.firstDate,
      pageSize,
      skip: skip,
    }).then(({ data, meta }) => {
      this.setState({ bookings: data, meta });
    });
  }

  gotoSkip = skip => () => {
    this.setState({ skip });
    this.getBookings(skip);
  }

  nextButtons = currentPage => {
    const { meta } = this.state;

    const lastPage = meta.total % pageSize === 0 ? meta.total / pageSize : parseInt(meta.total / pageSize) + 1;

    if (lastPage === currentPage) {
      return (<></>);
    }

    if (currentPage + 4 >= lastPage) {
      const nextPages = [];

      for (let i = currentPage + 1; i <= lastPage; i = i + 1) {
        nextPages.push(i);
      }

      return (<>
        {nextPages.map(page => (
          <Button
            key={page}
            onClick={this.gotoSkip((page - 1) * pageSize)}>
            {page}
          </Button>))}
      </>);
    }

    return (
      <>
        <Button onClick={this.gotoSkip(currentPage * pageSize)}>
          {currentPage + 1}
        </Button>
        <Button onClick={this.gotoSkip((currentPage + 1) * pageSize)}>
          {currentPage + 2}
        </Button>
        <Button onClick={this.gotoSkip((currentPage + 2) * pageSize)}>
          ...
        </Button>
        <Button onClick={this.gotoSkip((lastPage - 1) * pageSize)}>
          {lastPage}
        </Button>
      </>
    );

  }

  previousButtons = currentPage => {
    const { skip } = this.state;

    if (skip === 0) {
      return (<></>);
    }

    //0 6 12 18

    if (currentPage <= 5) {
      const previousPages = [];

      for (let i = 1; i < currentPage; i = i + 1) {
        previousPages.push(i);
      }

      return (<>
        {previousPages.map(page => (
          <Button
            key={page}
            onClick={this.gotoSkip((page - 1) * pageSize)}>
            {page}
          </Button>))}
      </>);
    }

    return (
      <>
        <Button onClick={this.gotoSkip(0)}>
          1
        </Button>
        <Button onClick={this.gotoSkip((currentPage - 4) * pageSize)}>
          ...
        </Button>
        <Button onClick={this.gotoSkip((currentPage - 3) * pageSize)}>
          {currentPage - 2}
        </Button>
        <Button onClick={this.gotoSkip((currentPage - 2) * pageSize)}>
          {currentPage - 1}
        </Button>
      </>
    );
  }

  render() {
    const {
      classes,
    } = this.props;
    const {
      bookings,
      meta,
      skip,
    } = this.state;

    const currentPage = (skip / pageSize) + 1;

    return (
      <div className={classes.container}>
        <div className={classes.topRow}>
          <div className={classes.eventsContainer}>
            {bookings.map(booking => (
              <ListItem
                booking={booking}
                key={booking.id}
              />
            ))}
          </div>
          <div className={classes.searchContainer}>
            <SearchPanel />
          </div>
        </div>
        <div className={classes.pagerContainer}>
          <div className={classes.pagerContent}>
            <Button
              disabled={skip === 0}
              onClick={this.gotoSkip(skip - pageSize)}
            >
              <KeyboardBackspace /> Prev
            </Button>
            {this.previousButtons(currentPage)}

            <Button
              className={classes.currentPage}
            >
              {currentPage}
            </Button>

            {this.nextButtons(currentPage)}

            <Button
              onClick={this.gotoSkip(skip + pageSize)}
              disabled={skip + pageSize >= meta.total}
            >
              Next  <KeyboardBackspace className={classes.rotate} />
            </Button>
          </div>
        </div>
      </div >
    );
  }
}

const mapDispatchToProps = {
  getBookings,
};

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(EventList));
