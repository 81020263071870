import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import OauthLoginButton from './OauthLoginButton';
import LoggingIn from './LoggingIn';
import AutoLogin from './AutoLogin';
import Chrome from 'Chrome';
import Error from 'Error';

class Authenticate extends Component {

  render() {
    const { user, catereaseCP } = this.props;

    return (
      <Switch>
        <Route exact path="/error/:message?" component={Error} />
        <Route exact path="/login/:guestTokenId" component={AutoLogin} />
        <Route exact path="/signin-oidc" component={LoggingIn} />
        <Route path="/" component={(!user || user.expired) && !catereaseCP ? OauthLoginButton : Chrome} />
      </Switch>
    );
  }
}

const mapStateToProps = state => ({
  user: state.oidc.user,
  catereaseCP: state.caterease.catereaseCP,
});

export default connect(mapStateToProps)(Authenticate);
