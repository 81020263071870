import { createUserManager } from 'redux-oidc';
import { ResetCatereaseCp } from 'reducers/caterease';

const thisSite = window.location.origin; //will be like http://localhost:3000

const userManagerConfig = {
  authority: window.env.REACT_APP_OAUTH_URL,
  client_id: window.env.REACT_APP_CLIENT_ID,
  redirect_uri: `${thisSite}/signin-oidc/`,
  response_type: 'id_token token',
  scope: 'openid profile cover-api',
  post_logout_redirect_uri: thisSite,
  automaticSilentRenew: true,
  silent_redirect_uri: `${thisSite}/silent-refresh.html`,
};

const createGuestUserManager = guestTokenId =>
  guestTokenId ?
    createUserManager({
      ...userManagerConfig,
      extraQueryParams: { guest_token: guestTokenId },
    }) :
    createUserManager({
      ...userManagerConfig,
    });

const regularUserManager = createGuestUserManager();

const logout = () => {
  return (_dispatch, getState) => {
    const oidcUser = getState().oidc.user;

    if (oidcUser) {
      const idToken = oidcUser.id_token;

      regularUserManager.signoutRedirect({ id_token_hint: idToken });
      regularUserManager.removeUser();
    } else {
      _dispatch(ResetCatereaseCp());
    }
  };
};

export { logout, regularUserManager, createGuestUserManager };

