import React, { useContext } from 'react';
import CompanyContext from 'company/CompanyContext';

export default () => {
  const {
    company,
  } = useContext(CompanyContext);

  return (
    <p dangerouslySetInnerHTML={{ __html: !!company ? company.termsCondition : '' }}></p>
  );
};
