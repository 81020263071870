import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { logout } from 'auth/userManager';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 1000,
    background: theme.palette.grey[100],
    width: '100vw',
    height: '100vh',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progress: {
    margin: 40,
  },
});

class Logout extends Component {
  componentDidMount() {
    const { history } = this.props;

    history.push('/');
    this.props.logout();
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.loading}>
          <CircularProgress className={classes.progress} />
          Logging out...
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = {
  logout,
};

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(Logout));
