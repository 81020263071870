import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ExpansionPanel from 'components/ExpansionPanel';
import { formatMoney } from 'utilities/PriceFormatter';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 0,
    border: `1px solid ${theme.palette.background.border}`,
    borderCollapse: 'separate',
    borderSpacing: '0 10px',
    padding: 10,
    '& th, & td': {
      padding: '7px 5px 7px 10px',
      fontSize: 14,
      "@media (max-width: 420px)": {
        fontSize: 12,
      },
    },
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
    '& .MuiTableRow-root': {
      verticalAlign: 'top',
    },
    "@media (max-width: 320px)": {
      '& th, & td': {
        padding: '7px 7px 7px 7px',
        fontSize: 11,
      },
      minWidth: 0,
    },
  },
  padBottom: {
    paddingBottom: 40,
  },
  borderBox: {
    '& th': {
      boxSizing: 'border-box',
      color: theme.palette.text.primary,
      border: 'none',
      fontSize: 12,
      paddingTop: 0,
    },
  },
  tableContents: {
    '& th, & td': {
      color: theme.palette.text.primary,
    },
  },
}));

const descriptionStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
};

export default function ({ section, isLast }) {
  const classes = useStyles();
  //isLast
  const customStyles = {
    root: !isLast && classes.padBottom,
  };

  return (
    <ExpansionPanel title={section.name} defaultExpanded={true} customStyles={customStyles}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.borderBox}>
            <TableCell style={{ width: 200 }}>Item</TableCell>
            <TableCell style={{ width: 100 }}>Quantity</TableCell>
            <TableCell style={{ width: 100 }}>Price</TableCell>
            <TableCell style={{ width: 100 }}>Total</TableCell>
            <TableCell>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableContents}>
          {section.items.map(row => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell>{row.quantity}</TableCell>
              <TableCell>${formatMoney(row.price)}</TableCell>
              <TableCell>${formatMoney(row.price * row.quantity)}</TableCell>
              <TableCell>
                <div
                  dangerouslySetInnerHTML={{ __html: row.description }}
                  style={descriptionStyle}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </ExpansionPanel>
  );
}
