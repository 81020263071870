import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import { connect } from 'react-redux';
import { ReduxState } from '../reducers/rootReducer';
import { CatereaseCpReceived, ResetCatereaseCp } from '../reducers/caterease';

import { createGuestUserManager, regularUserManager } from './userManager';
import FullPageLoader from 'components/FullPageLoader';

type Props = RouteComponentProps & ReduxProps & {
};

interface ReduxProps {
  catereaseCpReceived: (catereaseCP: string) => void;
  resetCatereaseCp: () => void;
}

class AutoLogin extends Component<Props> {
  componentDidMount() {
    const { history, match, catereaseCpReceived, resetCatereaseCp } = this.props;
    const { guestTokenId } = match.params;

    const coverGuestTokenLength = 20;
    const catereaseGuest = guestTokenId && guestTokenId.length !== coverGuestTokenLength;

    if (catereaseGuest) {
      regularUserManager
        .removeUser()
        .then(() => {
          catereaseCpReceived(guestTokenId);
          history.push('/');
        });

      return;
    }

    resetCatereaseCp();
    const userManagerWithGuestToken = createGuestUserManager(guestTokenId);

    userManagerWithGuestToken.removeUser()
      .then(() => {
        userManagerWithGuestToken.signinRedirect();
      });
  }

  render() {
    return (<FullPageLoader />);
  }
}

const mapStateToProps = (state: ReduxState) => ({
});

const mapDispatchToProps = (dispatch: any) => ({
  catereaseCpReceived: (catereaseCP: string) => dispatch(CatereaseCpReceived(catereaseCP)),
  resetCatereaseCp: () => dispatch(ResetCatereaseCp()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoLogin);
