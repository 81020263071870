import React, { Component } from 'react';
import { withStyles, WithStyles, Theme, createStyles } from '@material-ui/core/styles';
import { NavLink, Link } from 'react-router-dom';
// import { fade } from '@material-ui/core/styles/colorManipulator';
import { connect } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';

const styles = (theme: Theme) => createStyles({
  logo: {
    height: 40,
  },
  flexRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '1%',
    paddingLeft: '3%',
    paddingBottom: '1%',
    paddingRight: '3%',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.paper,
    //height: heightOfTopBar,
  },
  linksContainer: {
    paddingTop: 8,
  },
  link: {
    paddingLeft: 16,
    paddingRight: 16,
    position: 'relative',
    display: 'inline-block',
    textTransform: 'none',
    textDecoration: 'none',
    paddingBottom: 8,
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: 400,
    // letterSpacing: 1.4,
    '&:before, &:after': {
      content: '\'\'',
      position: 'absolute',
      bottom: 2,
      left: 0,
      right: 0,
      height: 2,
      width: 20,
      marginLeft: 33,
      backgroundColor: '#637280',
    },
    '&.active': {
      '&:before': {
        opacity: 1,
      },
      color: '#637280',
    },
    '&:not(.active)': {
      '&:before': {
        opacity: 0,
        transform: 'translateY(-8px)',
        transition: 'transform 0s cubic-bezier(0.175, 0.885, 0.320, 1.275), opacity 0s',
      },
      '&:after': {
        opacity: 0,
        transform: 'translateY(4px)',
        transition: 'transform .2s cubic-bezier(0.175, 0.885, 0.320, 1.275), opacity .2s',
      },
      '&:hover, &:focus': {
        '&:before, &:after': {
          opacity: 1,
          transform: 'translateY(0)',
        },
        '&:before': {
          transition: 'transform .2s cubic-bezier(0.175, 0.885, 0.320, 1.275), opacity .2s',
        },
        '&:after': {
          transition: 'transform 0s .2s cubic-bezier(0.175, 0.885, 0.320, 1.275), opacity 0s .2s',
        },
      },
    },
  },
});

type Props = ReduxProps & WithStyles<typeof styles> & {
}

interface ReduxProps {
  hideHome: boolean;
}

class Nav extends Component<Props> {

  isInBookings = (match, location) => {
    if (!match) {
      return location.pathname.match(/^\/booking\//);
    }

    return true;
  }

  render() {
    const { classes, hideHome } = this.props;

    return (
      <div className={classes.flexRow}>
        <Link to='/'>
          <img src="/logoCE.svg" alt="cover logo" className={classes.logo}></img>
        </Link>
        <div className={classes.linksContainer}>
          {!hideHome && <NavLink to="/" exact={true} className={classes.link} activeClassName="active">
            Home
          </NavLink>}
          <NavLink to="/bookings" className={classes.link} activeClassName="active" isActive={this.isInBookings}>
            Events
          </NavLink>
          <NavLink to="/contact" className={classes.link} activeClassName="active">
            Contact
          </NavLink>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  const coverGuest: boolean = state.oidc.user && state.oidc.user.profile['cover-BookingLookup'];
  const catereaseGuest: boolean = !!state.caterease.catereaseCP;
  const hideHome = coverGuest || catereaseGuest;

  return {
    hideHome,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Nav));
