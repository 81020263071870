import callApi from 'common/callApi';
import {
  FETCH_CHITCHAT_MESSAGES_BEGIN,
  FETCH_CHITCHAT_MESSAGES_SUCCESS,
  FETCH_CHITCHAT_MESSAGES_FAILURE,
} from 'common/constants';
import { CHIT_CHAT_RELATIONSHIPS } from 'common/chitChatTypes';

export const fetchChitChatBegin = bookingId => ({
  type: FETCH_CHITCHAT_MESSAGES_BEGIN,
  bookingId,
});

export const fetchChitChatSuccess = threads => ({
  type: FETCH_CHITCHAT_MESSAGES_SUCCESS,
  threads,
});

export const fetchChitChatFail = error => ({
  type: FETCH_CHITCHAT_MESSAGES_FAILURE,
  error,
});

export const fetchChitChat = bookingId => dispatch => {
  dispatch(fetchChitChatBegin(bookingId));
  dispatch(callApi(`v1/chitChat/${CHIT_CHAT_RELATIONSHIPS.booking}/${bookingId}`))
    .then(result => result.json())
    .then(threads => dispatch(fetchChitChatSuccess(threads)))
    .catch(error => dispatch(fetchChitChatFail(error)));
};

export function saveNewChitChatThread(chitChatType, chitChatThread) {
  return dispatch => dispatch(callApi(`v1/chitChat/${CHIT_CHAT_RELATIONSHIPS.booking}/${chitChatThread.relationshipId}/${chitChatType}`, { body: chitChatThread.baseMessage }));
}

export function saveChitChatComment(relationshipId, chitChatMessage) {
  return dispatch => dispatch(callApi(`v1/chitChat/${CHIT_CHAT_RELATIONSHIPS.booking}/${relationshipId}/thread/${chitChatMessage.chitChatThreadId}`, { body: chitChatMessage }));
}
