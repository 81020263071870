import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import DashItem from './DashItem';
import { withRouter } from 'react-router-dom';
import {
  getImage,
} from 'actions/events';
import { connect } from 'react-redux';

const styles = {
  container: {
    //maxWidth: 592,
    //width: '100%',
    width: 592,
    marginBottom: 60,
    display: 'inline-block',
    flexDirection: 'column',
    marginLeft: 50,
  },
  image: {
    height: 0,
    paddingTop: '67%', //seems like a reasonable way to set the image to 3:2 aspect ration 600px X 400px https://css-tricks.com/aspect-ratio-boxes/
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
  },
};

class ListItem extends Component {
  state = { styleWithImage: {} };

  componentDidMount() {
    this.loadImage();
  }

  loadImage = () => {
    if (!this.props.booking) {
      return;
    }
    this.props.getImage(this.props.booking.id).then(image => {
      if (image.content) {
        const styleWithImage = {
          backgroundImage: `url("data:image/gif;base64,${image.content}")`,
        };

        this.setState({ styleWithImage });
      }

    });
  }

  gotoBooking = () => {
    const {
      history,
      booking: { id },
    } = this.props;

    history.push(`/booking/${id}`);
  }

  render() {
    const {
      classes,
      booking,
    } = this.props;
    const {
      styleWithImage,
    } = this.state;

    return (
      <div
        className={classes.container}
        onClick={this.gotoBooking}
      >
        <div className={classes.image} style={styleWithImage}>

        </div>
        <DashItem booking={booking} />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getImage,
};

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(withRouter(ListItem)));
