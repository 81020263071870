import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  upcomingBookings: {
    fontSize: 38,
    color: theme.palette.grey[900],
  },
  topRow: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
  eventsDescription: {
    width: 620,
    fontSize: 19,
    paddingLeft: 40,
    color: theme.palette.grey[700],
    borderLeft: `solid 1px ${theme.palette.grey[400]}`,
  },
});

class SectionHeader extends Component {
  render() {
    const {
      classes,
      children,
      label,
    } = this.props;

    return (
      <div className={classes.topRow}>
        <div className={classes.upcomingBookings}>
          {label}
        </div>
        <div className={classes.eventsDescription}>
          {children}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SectionHeader);
