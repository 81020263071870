import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { getMenu } from 'actions/menu';
import { getEventLocation } from 'actions/events';
import FullPageLoader from 'components/FullPageLoader';
import MenuSection from './MenuSection';
import EventCard from './EventCard';
import { Grid } from '@material-ui/core';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    "@media (max-width: 420px)": {
      'flex-direction': 'column',
    },
  },
  label: {
    fontSize: 18,
    color: theme.palette.grey[900],
    marginTop: 20,
    marginLeft: 20,
  },
  pad: {
    "@media (max-width: 420px)": {
      padding: 0,
    },
  },
  rightColumn: {
    position: 'relative',  //for the loader
    paddingBottom: 26,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    "@media (max-width: 420px)": {
      padding: 0,
    },
  },
  leftBorder: {
    paddingBottom: 26,
    flexGrow: 1,
  },
  padLeft: {
    "@media (max-width: 420px)": {
      padding: '0px',
    },
  },
  menuSections: {
    backgroundColor: theme.palette.background.paper,
  },
  sectionHeader: {
    paddingTop: 20,
    paddingBottom: 20,
    fontSize: 14,
    "@media (max-width: 420px)": {
      fontSize: 12,
    },
  },
});

class MenuSections extends Component {
  state = {};

  async componentDidMount() {
    const {
      booking,
      getMenu,
      getEventLocation,
    } = this.props;

    this.setState({ selectedEventId: booking.events[0].id });

    for (let index = 0; index < booking.events.length; index++) {
      let event = booking.events[index];
      let menuDict = {};

      menuDict[`menu${event.id}`] = await getMenu(event.menuId); //using async so we don't spam out API... one request at a time
      this.setState(menuDict);

      let locationDict = {};

      locationDict[`location${event.id}`] = await getEventLocation(booking.id, event.id);
      this.setState(locationDict);
    }
  }

  async loadMenus() {

  }

  selectEvent = event => {
    this.setState({ selectedEventId: event.id });
  }

  render() {
    const {
      classes,
      booking: { events },
    } = this.props;
    const {
      selectedEventId,
    } = this.state;
    const menu = this.state[`menu${selectedEventId}`];

    return (
      <div>
        <Grid container={true} spacing={4} className={classes.container}>
          <Grid item={true} xs={12} sm={12} md={6}>
            <div className={classes.pad}>
              <div className={classes.sectionHeader}>
                Events
              </div>
              {events.map(event =>
                <EventCard
                  event={event}
                  menu={this.state[`menu${event.id}`]}
                  location={this.state[`location${event.id}`]}
                  isFocused={selectedEventId === event.id}
                  onSelectEvent={this.selectEvent}
                />
              )}
            </div>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={6}>
            <div className={classes.rightColumn}>
              {menu ?
                <>
                  <div className={classes.sectionHeader}>
                    Menu
                  </div>
                  <div>
                    <div className={classes.menuSections}>
                      {menu.menuSections.map((section, index) =>
                        <MenuSection
                          section={section}
                          key={section.id}
                          isLast={menu.menuSections.length === index + 1}
                        />
                      )}
                    </div>
                  </div>
                </>
                :
                <FullPageLoader height="100%" message="loading..." />
              }
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}
const mapDispatchToProps = {
  getMenu,
  getEventLocation,
};

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(MenuSections));
