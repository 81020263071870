import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Card,
  CardMedia,
} from '@material-ui/core';
// import moment from 'moment';

const styles = theme => ({
  file: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 20px 20px 0',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 157,
    height: 189,
    borderRadius: 3,
    border: `1px solid ${theme.palette.light.purple1}`,
    background: '#fff',
  },
  card: {
    display: 'flex',
    height: 105,
    width: 91,
    borderRadius: 0,
    margin: 'auto',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: 'auto',
    textAlign: 'center',
  },
  name: {
    fontSize: 14,
    textTransform: 'inherit',
    lineHeight: '22px',
  },
  info: {
    fontSize: 16,
    color: theme.palette.grey[500],
    minHeight: 90,
    overflow: 'auto',
  },
  button: {
    width: '100%',
    height: 32,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.common.black,
    border: `1px solid ${theme.palette.light.purple1}`,
    borderRadius: 3,
    textTransform: 'capitalize',
    marginTop: 5,
    fontSize: 12,
  },
});

class File extends Component {

  downloadFile = () => {
    const { file } = this.props;
    const byte = this.base64ToArrayBuffer(file.content);

    this.saveByteArray(file.name, byte, file.fileType);
  }

  base64ToArrayBuffer = base64Content => {
    const binaryString = window.atob(base64Content);
    const binaryLength = binaryString.length;
    const bytes = new Uint8Array(binaryLength);

    for (let i = 0; i < binaryLength; i++) {
      const ascii = binaryString.charCodeAt(i);

      bytes[i] = ascii;
    }

    return bytes;
  }

  saveByteArray(reportName, byte, fileType) {
    const blob = new Blob([byte], { type: fileType });

    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, this.props.file.name);

      return;
    }

    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(blob);
    link.download = reportName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link.href);
  };

  getFileTypeSvg = fileName => {
    let ext = '';

    if (fileName && fileName.length) {
      const lowerFileName = fileName.toLowerCase();

      ext = lowerFileName.split('.')[lowerFileName.split('.').length - 1];
    }

    return `/icons/${this.getSvgName(ext)}.svg`;
  }

  getSvgName = extension => {
    const fileTypes = {
      'xlsx': 'excel',
      'xls': 'excel',
      'pptx': 'ppt',
      'ppt': 'ppt',
      'pdf': 'pdf',
      'txt': 'txt',
      'jpg': 'image',
      'jpeg': 'image',
      'png': 'image',
      'gif': 'image',
      'tiff': 'image',
      'bmp': 'image',
      'doc': 'word',
      'docx': 'word',
    };

    return fileTypes[extension] || 'unknown';
  }

  render() {
    const {
      classes,
      file,
    } = this.props;

    const fileTypeSvg = this.getFileTypeSvg(file.name);

    return (
      <div className={classes.file}>
        <div className={classes.cardContainer}>
          <Card elevation={0} className={classes.card}>
            <CardMedia
              component="img"
              alt="Booking File"
              image={fileTypeSvg}
              title={file.name}
            />
          </Card>
          <div className={classes.details}>
            <div className={classes.name}>{file.name}</div>
            {/* <div className={classes.info}>{`${!!file.comment ? file.comment : ''} Shared on ${moment(file.dateShared).calendar()}.`}</div> */}
            {/* <div className={classes.description}>{file.info}</div> TODO: need Cover UI to add this*/}
          </div>
        </div>
        <div>
          <Button
            variant="outlined"
            classes={{ root: classes.button }}
            onClick={this.downloadFile}
          >
            Download
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(File);
