import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
  getBooking,getImage,
} from 'actions/events';
import EventDetails from './EventDetails';
// import BookingDetails from './BookingDetails';
import EventHeaderDetail from './EventHeaderDetail';

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 3%',
  },
});

class Event extends Component {
  state = { bookingId: 0, imgSrc: '' };

  componentDidMount() {
    const bookingId = this.props.match.params.bookingId;

    this.props.getBooking(bookingId).then(booking => {
      this.setState({ bookingId: booking.id });
    });
    this.loadImage();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.booking !== this.props.booking) {
      this.loadImage();
    }
  }

  loadImage = () => {
    if (!this.props.booking) {
      return;
    }
    this.props.getImage(this.props.booking.id).then(image => {
      if (image.content) {
        const imgSrc = `data:image/gif;base64, ${image.content}`;

        this.setState({ imgSrc });
      };
    });
  };

  render() {
    const {
      classes,
      match,
      booking,
    } = this.props;
    const { bookingId,imgSrc } = this.state;

    if (!bookingId || !match || !match.params || booking.id !== bookingId) {
      return null; //render nothing until the correct booking is loaded
    }

    return (
      <div>
        <EventHeaderDetail  booking={booking} imgSrc={imgSrc}/>
        <div className={classes.container}>
          <EventDetails booking={booking} />
          {/* <BookingDetails bookingId={booking.id} /> */}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getBooking,
  getImage,
};

const mapStateToProps = state => ({
  booking: !!state.api.booking ? state.api.booking : {},
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Event));
