import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import { fade } from '@material-ui/core/styles/colorManipulator';
import CompanyName from 'header/CompanyName';
import classNames from 'classnames';

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.primary.contrastText,
  },
  topRow: {
    display: 'flex',
    height: 195,
    alignItems: 'center',
    "@media (max-width: 420px)": {
      flexDirection: 'column',
    },
  },
  bottomRow: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 83,
    alignItems: 'center',
    borderTop: `solid 1px ${fade(theme.palette.primary.contrastText, .1)}`,
    flexDirection: 'column',
  },
  link: {
    textTransform: 'uppercase',
    textDecoration: 'none',
    color: theme.palette.primary.contrastText,
  },
  padLeft: {
    paddingLeft: 100,
    "@media (max-width: 420px)": {
      paddingLeft: 0,
    },
  },
  padRight: {
    paddingRight: 40,
    "@media (max-width: 420px)": {
      paddingRight: 0,
    },
  },
  content: {
    width: 1240,
  },
  poweredBy: {
    color: fade(theme.palette.primary.contrastText, .6),
    paddingLeft: 50,
    "@media (max-width: 320px)": {
      paddingLeft: 0,
    },
  },
  footerLink: {
    "@media (max-width: 320px)": {
      display: 'grid',
      justifyItems: 'center',
    },
  },
});

class Bar extends Component {
  render() {
    const {
      classes,
    } = this.props;

    return (<div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.topRow}>
          <CompanyName fontSize={28} />
          <NavLink to="/" exact={true} className={classNames(classes.padLeft, classes.padRight, classes.link)} >
            Home
          </NavLink>
          <NavLink to="/bookings" className={classNames(classes.padRight, classes.link)}>
            Events
          </NavLink>
          <NavLink to="/contact" className={classNames(classes.padRight,classes.link)}>
            Contact
          </NavLink>
        </div>
        <div className={classes.bottomRow}>
          <div className={classes.poweredBy}>Powered By Cover</div>
          <div className={classes.footerLink}>
            <NavLink to="/privacy" className={classes.link}>
              Privacy Policy
            </NavLink>
            <NavLink to="/tos" className={classes.link}>
              Terms of Service
            </NavLink>
          </div>
        </div>

      </div>
    </div>);
  }
}

export default withStyles(styles)(Bar);
