import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from 'components/Tabs';
import { connect } from 'react-redux';
import {
  getImage,
} from 'actions/events';
import Overview from './Overview';
import MenuSections from './MenuSections';
import ChitChat from 'components/ChitChat/ChitChat';
import Files from 'components/Files';

const styles = {
  container: {
    display: 'flex',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
  },
};

const tabNames = ["Overview", "Menu", "Chit Chat", "Files"];

class EventDetails extends Component {
  state = { selectedTab: 0,
    imgSrc: '' };

  componentDidMount() {
    this.loadImage();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.booking !== this.props.booking) {
      this.loadImage();
    }
  }

  loadImage = () => {
    if (!this.props.booking) {
      return;
    }
    this.props.getImage(this.props.booking.id).then(image => {
      if (image.content) {
        const imgSrc = `data:image/gif;base64, ${image.content}`;

        this.setState({ imgSrc });
      };
    });
  };

  tabChanged = newValue => {
    this.setState({ selectedTab: newValue });
  }

  render() {
    const { classes, booking } = this.props;
    const {
      selectedTab,
      imgSrc,
    } = this.state;
    
    return (
      <div className={classes.container}>
        <div className={classes.content}>
          <Tabs value={selectedTab} tabNames={tabNames} onChange={this.tabChanged} />
          {selectedTab === 0 && <Overview booking={booking} imgSrc={imgSrc}/>}
          {selectedTab === 1 && <MenuSections booking={booking} />}
          {selectedTab === 2 && <ChitChat booking={booking} />}
          {selectedTab === 3 && <Files bookingId={booking.id} />}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getImage,
};

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(EventDetails));
