import { createMuiTheme } from '@material-ui/core/styles';

//Default Theme
export const defaultTheme = createMuiTheme({
  palette: {
    grey: {
      '50': '#fafafa', //250
      '100': '#f5f5f5',//245 //using in place of #f3f2f2
      '200': '#eeeeee',//238
      '300': '#e0e0e0',//224 //using in place of #dddbda
      '400': '#bdbdbd',//189 //using in place of rgba(0, 0, 0, [0.1|0.2|0.3]) (spacer colors)
      '500': '#9e9e9e',//153 //using in place of #97A3B4
      '600': '#757575',//117 //using in place of rgba(0, 0, 0, 0.54)
      '700': '#616161',//97
      '800': '#333333',//51 //overridden from MD's #424242 //66
      '900': '#212121',//33 //using in place of #2B2826
      'A100': '#d5d5d5',//213 DDDBDA
      'A200': '#aaaaaa',//170
      'A400': '#303030',//48
      'A700': '#616161',//97
    },
    background: {
      default: '#f5f5f5',
      paper: '#ffffff',
      border: '#F0F3F4',
    },
    blue: { //let's document all shades of blue and hopefully we can get them to agree on what the palette is
      blue1: '#4A90E2',
      blue2: '#0070D2',
      blue3: '#2189F8',
      blue4: '#1589EE',
      blue5: '#3493F8',
      blue6: '#00215B', //using in place 002257
    },
    common: {
      black: "#000000",
      white: "#FFFFFF",
      success: '#0ead4e',
      magenta: '#9647AA',
      bluegrey: '#95AEC5',
      red: '#E6492D',
    },
    light: {
      purple1: '#8390B8',
      purple2: 'rgba(131, 144, 184, 0.6)',
      red: '#E4657F',
      lightgrey: '#F3F2F2',
      darkgrey: '#DDDBDA',
    },
    text: {
      primary: '#3E3F42',
      secondary: '#9EA0A5',
    },
  },
});

export default defaultTheme;
