import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  LocalBar,
  AttachMoney,
} from '@material-ui/icons';
import SpendingItem from './SpendingItem';

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[900],
    paddingTop: 38,
    paddingBottom: 38,
  },
  contents: {
    width: 1240,
    display: 'flex',
    justifyContent: 'space-between',
  },
  lineLabel: {
    fontSize: 28,
  },
  thanksLabel: {
    fontSize: 20,
  },
  items: {
    display: 'flex',
  },
});

class SpendingSummary extends Component {

  render() {
    const {
      classes,
      totalOnBooks,
      totalCompleted,
      dollarsSpent,
    } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.contents}>
          <div>
            <div className={classes.lineLabel}>
              Event Details &amp; History.
            </div>
            <div className={classes.thanksLabel}>
              We appreciate your loyalty.
            </div>
          </div>
          <div className={classes.items}>
            <SpendingItem icon={LocalBar} number={totalCompleted} label="Completed Events" />
            <SpendingItem icon={AttachMoney} number={dollarsSpent} label="Dollars Spent" />
            <SpendingItem icon={LocalBar} number={totalOnBooks} label="Events on the books" />

          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SpendingSummary);
