import React, { Component } from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import {
  Button,
  Checkbox,
  ExpansionPanel,
  ExpansionPanelSummary,
  FormControlLabel,
  ExpansionPanelDetails,
  FilledInput,
  Grid,
  Card,
  CardContent,
  Divider,
} from '@material-ui/core';
import {
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import {
  getFinancialDetails,
  confirmBooking,
  makePayment,
  getPaymentDetailsById,
  makeCoverPaymentCall,
} from 'actions/events';
import classNames from 'classnames';
import CompanyTerms from 'components/CompanyTerms';
import { ReduxState } from 'reducers/rootReducer';
import CompanyContext from 'company/CompanyContext';
import { formatMoney } from 'utilities/PriceFormatter';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent, DialogTitle } from 'components/DialogTitle';
import PrimaryButton from 'components/Buttons/PrimaryButton';

const styles = (theme: any) => createStyles({
  paper: {
    width: '800px',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    "@media (max-width: 425px)": {
      'flex-direction': 'column',
      'padding': '5',
    },
    "@media (max-width: 380px)": {
      'flex-direction': 'column',
      'padding': '5',
    },
  },
  extraPadding: {
    padding: '0px 24px',
    "@media (max-width: 420px)": {
      padding: 0,
    },
  },
  label: {
    color: theme.palette.blue.blue4,
    fontSize: 30,
    marginTop: '-5px',
    "@media (max-width: 420px)": {
      fontSize: 25,
      marginTop: 'auto',
    },
  },
  button: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.common.black,
    border: `1px solid ${theme.palette.light.purple1}`,
    verticalAlign: 'bottom',
    height: 48,
    width: 219,
    "@media (max-width: 420px)": {
      marginTop: 20,
      width: '100%',
      height: 'initial',
    },
  },
  buttonDisabled: {
    backgroundColor: 'rgba(0, 0, 0, 0.26)',
  },
  icon: {
    verticalAlign: 'middle',
    display: 'inline-block',
  },
  imgContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    "@media (max-width: 420px)": {
      width: '100%',
      paddingLeft: '24px',
    },
  },
  imageLogo: {
    maxWidth: '200px',
  },
  topRow: {
    display: 'flex',
    fontSize: 18,
    paddingTop: 25,
    flexDirection: 'column',
    "@media (max-width: 420px)": {
      'flex-direction': 'column',
    },
  },
  topHeader: {
    display: "flex",
    padding: "10px 24px",
    backgroundColor: theme.palette.light.purple2,
    flexDirection: "row",
    borderLeft: `1px solid ${theme.palette.light.purple2}`,
    borderTop: `1px solid ${theme.palette.light.purple2}`,
    borderRight: `1px solid ${theme.palette.light.purple2}`,
  },
  headerTitle: {
    fontSize: 18,
    fontWeight: 400,
  },
  bookingDetails: {
    flexGrow: 1,
    justifyContent: "space-between",
    alignContent: "center",
    padding: '1em',
    fontSize: 14,
  },
  bottomHeader: {
    display: "flex",
    alignContent: "center",
    padding: "10px 24px",
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.background.border}`,
  },
  field: {
    fontSize: 14,
    paddingTop: 5,
  },
  subTitle: {
    fontSize: 13,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  financialsContainer: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    marginBottom: 36,
    "@media (max-width: 420px)": {
      fontSize: 12,
    },
  },
  financials: {
    flexGrow: 1,
    marginBottom: 8,
  },
  financialSummary: {
    display: 'flex',
    padding: 15,
    justifyContent: 'center',
    backgroundColor: theme.palette.light.lightgrey,
  },
  cardContainer: {
    display: 'flex',
    color: theme.palette.text.lightprimary,
    margin: 5,
    padding: 15,
    border: `1px solid ${theme.palette.background.border}`,
  },
  financials2: {
    width: 320,
    display: 'flex',
    fontSize: 14,
    flexDirection: 'column',
  },
  financialsHeader: {
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
    fontSize: 18,
    backgroundColor: theme.palette.light.purple2,
    marginTop: 10,
    marginBottom: 10,
  },
  finacialsField: {
    display: 'flex',
    marginBottom: 20,
    justifyContent: 'space-between',
  },
  finacialsField2: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 20,
  },
  payments: {
    flexGrow: 1,
    marginBottom: 8,
  },
  paymentsContainer: {
    display: 'flex',
    color: theme.palette.text.primary,
    padding: "20px 40px",
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.background.border}`,
    justifyContent: 'space-between',
  },
  paymentsField: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    width: '35%',
  },
  bookingInfo: {
    color: theme.palette.text.secondary,
    "@media (max-width: 420px)": {
      textAlign: 'center',
      paddingBottom: '15px',
    },

  },
  topLeft: {
    paddingRight: 50,
    marginRight: 50,
    borderRight: `solid 1px ${theme.palette.grey[400]}`,
    height: '13vh',
    "@media (max-width: 420px)": {
      paddingRight: 0,
      marginRight: 0,
      borderRight: 'none',
    },
  },
  topMiddle: {
    paddingRight: 25,
    marginRight: 25,
  },
  sectionHeader: {
    paddingTop: 20,
    paddingBottom: 20,
    fontSize: 14,
    "@media (max-width: 420px)": {
      fontSize: 12,
    },
  },
  alignTable: {
    "@media (max-width: 420px)": {
      float: 'right',
    },
  },
  hr: {
    borderTop: `solid 1px ${theme.palette.grey[400]}`,
    maxWidth: 300,
  },
  money: {
    color: theme.palette.blue.blue5,
    textAlign: 'right',
    paddingLeft: 30,
  },
  failure: {
    color: theme.palette.error.dark,
  },
  success: {
    color: theme.palette.common.success,
  },
  confirmationContainer: {
    margin: '25px 0',
    width: '100%',
    border: `1px solid ${theme.palette.background.border}`,
  },
  confirmedContact: {
    lineHeight: '36px',
  },
  confirmedDate: {
    color: theme.palette.grey[500],
    lineHeight: '36px',
    marginLeft: '16px',
  },
  termsContainer: {
    width: '100%',
  },
  termsText: {
    color: theme.palette.grey[500],
  },
  termsControls: {
    textAlign: 'right',
  },
  termsInput: {
    height: '48px',
    margin: '0 25px',
  },
  hidden: {
    visibility: 'hidden',
  },
  alignLeftBooking: {
    "@media (max-width: 420px)": {
      paddingRight: '21px',

    },
  },
});

type Props = WithStyles<typeof styles> & {
  booking: any;
  catereaseCP: string;
  getFinancialDetails: Function;
  confirmBooking: Function;
  imgSrc: string;
  makePayment: Function;
  getPaymentDetailsById: Function;
  makeCoverPaymentCall: Function;
};

interface State {
  financials: any;
  conditionsAgreed: boolean;
  confirmedContact: string;
  termsExpended: boolean;
  openPaymentModel: boolean;
  openInvoiceModel: boolean;
  documentId: string;
  docPublicUrl: string;
  showInvoiceButton: boolean;
  paymentDetails: any;
}

const initialState: State = {
  financials: undefined,
  conditionsAgreed: false,
  confirmedContact: '',
  termsExpended: false,
  openPaymentModel: false,
  openInvoiceModel: false,
  documentId: '',
  docPublicUrl: '',
  showInvoiceButton: false,
  paymentDetails: undefined,
};

class Overview extends Component<Props, any> {
  public header: any = {
    'Authorization': `API-Key ${window.env.REACT_APP_PANDA_API_KEY}`,
    'Content-Type': 'application/json',
    'accept': "application/json",

  }

  submitCheckTimer: any;

  state = initialState;

  static contextType = CompanyContext;

  context!: React.ContextType<typeof CompanyContext>

  componentDidMount() {
    const {
      booking,
      getFinancialDetails,

    } = this.props;

    getFinancialDetails(booking.id).then(financials => {
      const conditionsAgreed = booking && !!booking.confirmedContact;

      this.setState({ financials, conditionsAgreed });
      this.makeInvoiceTemplateCall();
    });
  }

  makeInvoiceTemplateCall() {
    const { booking } = this.props;
    const { financials } = this.state;

    if (booking && financials) {

      const payload = {
        "name": `${booking.account.name}Quote Final`,
        "template_uuid": "97U26n9uJsmevRwkeVc66L",
        "recipients": [
          {
            "email": `sonny.elhamahmy@axiaprime.com`,
            "first_name": `${booking.contact.name}`,
            "last_name": `elhamahmy`,
            "role": "Client",
          },
        ],
        "fields": {
          "Client.Address": {
            "value": `${booking.account.streetAddress1}`,
          },
          "Sub.Total": {
            "value": `$${formatMoney(financials.subTotal.subTotal)}`,
          },
          "Tax": {
            "value": `$${formatMoney(financials.subTotal.taxCharged)}`,
          },
          "Service.Charge": {
            "value": `$${formatMoney(financials.subTotal.serviceCharged)}`,
          },
          "Estimate.Total.Charges": {
            "value": `$${formatMoney(financials.totalWithDiscount)}`,
          },
          "Total.Paid": {
            "value": `$${formatMoney(financials.paid)}`,
          },
          "Balance.Due": {
            "value": `$${formatMoney(financials.balance)}`,
          },
        },
        "images": [
          {
            "name": "Image 1",
            "urls": [
              "https://s3.amazonaws.com/pd-static-content/public-docs/pandadoc-panda-bear.png",
            ],
          },
        ],
      };

      if (!localStorage.getItem('documentId') || localStorage.getItem('documentId') === "undefined") {
        this.getInvoiceTemplateWithGivenData(JSON.stringify(payload));
      } else {
        this.setState({ documentId: localStorage.getItem('documentId') }, () => {
          this.getDocStatusById();
          this.getInvoiceURL(this.state.documentId);
        });
      }
    }

  }

  getInvoiceTemplateWithGivenData(body: any,) {
    const headers = this.header;
    const method: any = "POST";

    fetch("https://api.pandadoc.com/public/v1/documents", { method, headers, body })
      .then(async (response: any) => {
        await response.json().then(result => {
          if (result) {
            localStorage.setItem('documentId', result.id);
            this.setState({ documentId: result.id });
            this.getInvoiceURL(result.id);
          }
        });
      })
      .catch(err => console.error(err));
  }

  getInvoiceURL(docId: string) {
    const body = {
      "message": "Hello! This document was sent from the PandaDoc API.",
      "silent": true,
    };

    if (docId) {
      fetch(`https://api.pandadoc.com/public/v1/documents/${docId}/send`,
        { method: 'POST', headers: this.header, body: JSON.stringify(body) }).then(async (response: any) => {
        await response.json().then(result => {
          if (result && result.recipients) {
            this.setState({ docPublicUrl: result.recipients[0].shared_link });
            this.getDocStatusById();
          }
        }).catch(err => {
          console.error(err);
        });
      }).catch(err => console.error(err));
    }
  }

  getDocStatusById() {
    const { documentId } = this.state;
    const Option: RequestInit = {
      headers: this.header,
      method: "GET",
    };

    fetch(`https://api.pandadoc.com/public/v1/documents/${documentId}`, Option)
      .then(async (response: any) => {
        await response.json().then(result => {
          if (result.status === 'document.uploaded' ||
            result.status === 'document.viewed' || result.status === 'document.draft'
            || result.status === 'document.sent') {
            this.setState({ showInvoiceButton: true });
          } else if (result.status === 'document.completed' ||
            result.status === 'document.approved') {
            this.setState({ showInvoiceButton: false });
          }
        });
      })
      .catch(err => console.error(err));
  }

  openPaymentModel = () => {

    if (!this.getPaymentDetailsById('openModel')) {
      const { makePayment } = this.props;
      const { financials } = this.state;
      const { company } = this.context;
      const paymentDetails: any = {
        totalAmount: financials.balance,
        currency: 'USD',
        serviceChargeAmount: financials.subTotal.serviceCharged,
        discountAmount: financials.subTotal.discount,
        taxAmount: financials.subTotal.taxCharged,
        tipAmount: 0,
      };
      const payload: any = {
        merchantAccountId: company.merchant_account_id,
        autoCapture: false,
        disableTips: false,
        nameOnCard: '    ',
        paymentDetails: paymentDetails,
      };

      makePayment(payload).then(paymentDetails => {
        if (paymentDetails) {
          localStorage.setItem('UUID', paymentDetails.id);
          this.setState({ paymentDetails });
          this.setState({ openPaymentModel: !this.state.openPaymentModel });
        }
      });
    } else {
      this.setState({ openPaymentModel: !this.state.openPaymentModel });
    }

  }

  handleClosePaymentModel = () => {
    this.setState({ openPaymentModel: false });
    this.getPaymentDetailsById("closeModel");
  }

  getPaymentDetailsById = (action: string): boolean => {
    let value = true;

    if (localStorage.getItem('UUID')) {
      const { getPaymentDetailsById, makeCoverPaymentCall, booking } = this.props;

      getPaymentDetailsById(localStorage.getItem('UUID')).then(paymentDetails => {
        if (paymentDetails) {
          localStorage.setItem('UUID', paymentDetails.id);
          this.setState({ paymentDetails });
          if (action === 'closeModel' && paymentDetails.state !== 'RequiresAction') {
            makeCoverPaymentCall(paymentDetails, booking.id).then(coverResponse => {
              localStorage.removeItem('UUID');
              window.location.reload();
            }).catch(err => {
              console.error(err);
              window.location.reload();
            }
            );
          }
          if (action === 'openModel' && paymentDetails.state !== 'RequiresAction') {
            value = false;
          } else {
            value = true;
          }
        }
      });
      if (action === 'openModel') {
        this.setState({ openPaymentModel: !this.state.openPaymentModel });
      }
    } else {
      value = false;
    }

    return value;
  }

  openInvoiceSignModel = () => {
    this.setState({ openInvoiceModel: !this.state.openInvoiceModel });
  }

  formatEndDate(startDateTime, endDateTime) {
    if (startDateTime.hasSame(endDateTime, 'day')) {
      return endDateTime.toFormat('h:mm a');
    }

    return endDateTime.toFormat('cccc, LLLL d, h:mm a');
  }

  amountStyle = payment => {
    if (payment.status === 'past-due') {
      return this.props.classes.failure;
    }
    if (payment.status === 'paid') {
      return this.props.classes.success;
    }

    return this.props.classes.money;
  }

  additionalText = payment => {
    if (payment.status === 'past-due') {
      return ' - PAST DUE';
    }
    if (payment.status === 'paid') {
      return ' - PAID';
    }

    return '';
  }

  handlePanelOnClick = event => {
    const { termsExpended } = this.state;

    event.stopPropagation();
    event.preventDefault();
    this.setState({ termsExpended: !termsExpended });
  }

  render() {
    const { classes, booking, catereaseCP } = this.props;
    const { financials, confirmedContact, termsExpended, openPaymentModel, openInvoiceModel, showInvoiceButton, paymentDetails } = this.state;
    const { company } = this.context;
    const coverUser = !catereaseCP;
    const hasTermsAndCondition = company && !!company.termsCondition;
    const confirmedContactPresent = booking && !!booking.confirmedContact;
    const startDateTime = DateTime.fromISO(booking.startDateTime);
    const endDateTime = DateTime.fromISO(booking.endDateTime);
    const currentDate = new Date();
    const salesRep = booking && booking.account ? booking.account.salesRep : null;

    return (
      <div>
        <div className={classes.topRow}>
          <div className={classes.topHeader}>
            <Grid container={true} className={classes.bookingDetails}>
              <Grid item={true}>
                <div className={classes.headerTitle}>
                  {booking.name}
                </div>
                <div className={classes.field}>
                  {booking.status}
                </div>
              </Grid>
              <Grid item={true} style={{ textAlign: 'right' }}>
                <div className={classes.field}>
                  Event Total
                </div>
                <div className={classes.headerTitle}>
                  ${formatMoney(booking.total)}
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={classes.bottomHeader}>
            <Grid container={true} className={classes.bookingDetails}>
              <Grid item={true}>
                <div>
                  Client
                </div>
                <div className={classNames(classes.field, classes.subTitle)}>
                  {booking.name}
                </div>
              </Grid>
              <Grid item={true}>
                <div>
                  Event Manager
                </div>
                <div className={classNames(classes.field, classes.subTitle)}>
                  {booking.eventManager ? booking.eventManager : salesRep && salesRep !== '' ? salesRep : '- '}
                </div>
              </Grid>
              <Grid item={true}>
                <div>
                  Start Date
                </div>
                <div className={classNames(classes.field, classes.subTitle)}>
                  {startDateTime.toFormat('M/dd/yyyy')}
                </div>
              </Grid>
              <Grid item={true}>
                <div>
                  End Date
                </div>
                <div className={classNames(classes.field, classes.subTitle)}>
                  {endDateTime.toFormat('M/dd/yyyy')}
                </div>
              </Grid>
              <Grid item={true}>
                <div>
                  Start Time
                </div>
                <div className={classNames(classes.field, classes.subTitle)}>
                  {startDateTime.toFormat('h:mma')}
                </div>
              </Grid>
              <Grid item={true}>
                <div>
                  End Time
                </div>
                <div className={classNames(classes.field, classes.subTitle)}>
                  {endDateTime.toFormat('h:mma')}
                </div>
              </Grid>
            </Grid>
          </div>

          {/* <div className={classes.topLeft}>
            <div className={classes.label}>
              {booking.name}
            </div>
            <div className={booking.status ? classes.bookingInfo : `${classes.bookingInfo} ${classes.hidden}`}>
              Status | {booking.status}
            </div>
          </div> */}
          {/* <div className={classes.alignLeftBooking}>
            <div>
              {booking && booking.contact && booking.contact.name &&
                <div >
                  <PersonOutline className={classes.icon} /> {booking.contact.name}
                </div>
              }
              <div >
                <EventNote className={classes.icon} /> {startDateTime.toFormat('MMMM dd yyyy')}
              </div>
              <div >
                <AccessTime className={classes.icon} /> {startDateTime.toFormat('hh:mma').toLowerCase()} - {endDateTime.toFormat('hh:mma').toLowerCase()}
              </div>
              <div>
                <Place className={classes.icon} /> {booking.name}
              </div>
            </div>
            <div style={{ fontSize: 25 }}>
              {startDateTime.toFormat('EEEE , MMMM dd, hh:mma')}- {endDateTime.toFormat('EEEE , MMMM dd, hh:mma')}
            </div>
            <div className={classes.bookingInfo}>
              Event Manager :  {booking.eventManager ? booking.eventManager : salesRep && salesRep !== '' ? salesRep : '- '}
            </div>
            <div className={booking.eventManager ? classes.bookingInfo : `${classes.bookingInfo} ${classes.hidden}`}>
              Event Manager | {booking.eventManager}
            </div>
          </div> */}
          {/* <div className={classes.imgContent}>
            <CompanyName fontSize={28} />
            {!!imgSrc &&
              <img src={imgSrc} alt="logo" className={classes.imageLogo} />
            }
          </div> */}

        </div>
        {financials &&
          <div className={classes.financialsContainer}>
            <Grid container={true} spacing={4} className={classes.container}>
              <Grid item={true} xs={12} sm={12} md={6} className={classes.payments}>
                <div className={classes.sectionHeader}>Payments</div>
                <Grid container={true} className={classes.paymentsContainer}>
                  <Grid item={true}>
                    <div>
                      <div>Event Total</div>
                      <div className={classNames(classes.field, classes.subTitle)}>${formatMoney(financials.totalWithDiscount)}</div>
                    </div>
                  </Grid>
                  <div className={classes.paymentsField}>
                    <Grid item={true}>
                      <div>
                        <div>Total Paid</div>
                        <div className={classNames(classes.field, classes.subTitle)}>${formatMoney(financials.paid)}</div>
                      </div>
                    </Grid>
                    <Grid item={true}>
                      <div>
                        <div>Total Due</div>
                        <div className={classNames(classes.field, classes.subTitle)}>${formatMoney(financials.balance)}</div>
                      </div>
                    </Grid>
                  </div>
                </Grid>
                {/* <table className={classes.alignTable}>
                  <tr>
                    <td>Total Paid</td>
                    <td style={{ paddingRight: 20 }}></td>
                    <td className={classes.money} >${formatMoney(financials.paid)}</td>
                  </tr>
                  <tr>
                    <td >Balance Due</td>
                    <td style={{ paddingRight: 20 }}></td>
                    <td className={classes.money} >${formatMoney(financials.balance)}</td>
                  </tr>
                </table> */}
                {/* <div className={classes.hr}></div>
              {payments.map(payment => (<div key={payment.date}>{payment.date}: <span className={this.amountStyle(payment)}>${payment.amount + this.additionalText(payment)}</span></div>))} */}
              </Grid>
              <Grid item={true} xs={12} sm={12} md={6} className={classes.financials}>
                <div className={classes.sectionHeader}>Financial</div>
                <div className={classes.financialSummary}>
                  <Card elevation={0} className={classes.cardContainer}>
                    <div className={classes.financials2}>
                      <div className={classes.financialsHeader}>Financials</div>
                      <CardContent>
                        <div className={classes.finacialsField}>
                          <div>Order Subtotal</div>
                          <div>${formatMoney(financials.subTotal.subTotal)}</div>
                        </div>
                        <div className={classes.finacialsField}>
                          <div>Tax</div>
                          <div>${formatMoney(financials.subTotal.taxCharged)}</div>
                        </div>
                        <div className={classes.finacialsField}>
                          <div>Service Charge</div>
                          <div>${formatMoney(financials.subTotal.serviceCharged)}</div>
                        </div>
                        <Divider />
                        <div className={classes.finacialsField2}>
                          <div>Estimated Total Charges</div>
                          <div>${formatMoney(financials.totalWithDiscount)}</div>
                        </div>
                        {coverUser &&
                          <div className={classes.finacialsField2}>
                            <div>Minimum Spend: <span className={financials.remaining <= 0 ? classes.success : classes.failure}>${formatMoney(financials.minimum)} - {financials.remaining <= 0 ? 'Met' : 'Not Met'}</span></div>
                          </div>
                        }
                      </CardContent>
                    </div>
                  </Card>
                </div>
                {/* <table className={classes.alignTable}>
                  <tr>
                    <td>Subtotal</td>
                    <td></td>
                    <td className={classes.money}>${formatMoney(financials.subTotal.subTotal)}</td>
                  </tr>
                  <tr>
                    <td>Tax</td>
                    <td></td>
                    <td className={classes.money}>${formatMoney(financials.subTotal.taxCharged)}</td>
                  </tr>
                  <tr>
                    <td style={{ borderBottom: "1px dotted #ddd" }}>Service Charge</td>
                    <td style={{ borderBottom: "1px dotted #ddd" }}></td>
                    <td className={classes.money} style={{ borderBottom: "1px dotted #ddd" }}>${formatMoney(financials.subTotal.serviceCharged)}</td>
                  </tr>
                  <tr>
                    <td>Estimated Total Charges</td>
                    <td></td>
                    <td className={classes.money}>${formatMoney(financials.totalWithDiscount)}</td>
                  </tr>

                </table>
                {coverUser &&
                  <div>Minimum Spend: <span className={financials.remaining <= 0 ? classes.success : classes.failure}>${formatMoney(financials.minimum)} - {financials.remaining <= 0 ? 'Met' : 'Not Met'}</span></div>
                } */}
              </Grid>
            </Grid>
            <Grid container={true} className={classes.container}>
              <Grid item={true} xs={12} sm={12} md={12}>
                <Grid container={true} style={{ display: 'grid', justifyContent: 'space-around', textAlign: 'center' }}>
                  <Grid item={true} xs={12} sm={12} md={6} lg={6} style={{ marginBottom: 8 }}>
                    {/* {(financials.balance > 0) && <Button variant="outlined" classes={{ root: classes.button }} onClick={() => this.openPaymentModel()} >
                      Make a payment
                    </Button>} */}

                  </Grid>
                  {showInvoiceButton && <Grid item={true} xs={12} sm={12} md={6} lg={6}>
                    <PrimaryButton variant="outlined" classes={{ root: classes.button }} onClick={() => this.openInvoiceSignModel()} >
                      Invoice E-sign
                    </PrimaryButton>
                  </Grid>}

                </Grid>
              </Grid>
            </Grid>
            {hasTermsAndCondition &&
              <div className={classes.confirmationContainer}>
                <ExpansionPanel expanded={termsExpended} elevation={0}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    id="additional-actions1-header"
                    onClick={event => this.handlePanelOnClick(event)}
                  >
                    <FormControlLabel
                      aria-label="Acknowledge"
                      onClick={event => this.handlePanelOnClick(event)}
                      onFocus={event => event.stopPropagation()}
                      control={
                        <Checkbox
                          disabled={confirmedContactPresent}
                          checked={confirmedContactPresent}
                        />
                      }
                      label="I agree to the contract terms and conditions"
                    />
                    {confirmedContactPresent &&
                      <span className={classes.confirmedContact}>{booking.confirmedContact}</span>
                    }
                    {booking.confirmedContactDate &&
                      <span className={classes.confirmedDate}>{DateTime.fromISO(booking.confirmedContactDate).toFormat('LLLL d, yyyy')}</span>
                    }
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className={classes.termsContainer}>
                      <span className={classes.termsText}>
                        <CompanyTerms />
                      </span>
                      {!confirmedContactPresent &&
                        <div className={classes.termsControls}>
                          <span className={classes.termsText}>
                            {currentDate.toDateString()}
                          </span>
                          <FilledInput
                            id="full-name"
                            className={classes.termsInput}
                            value={confirmedContact}
                            placeholder="Full Name"
                            onChange={event => this.setState({ confirmedContact: event.target.value })}
                            inputRef={input => input && input.focus()}
                          />
                          <Button
                            variant="outlined"
                            classes={{ root: classes.button, disabled: classes.buttonDisabled }}
                            onClick={() => {
                              this.props.confirmBooking(booking, confirmedContact);
                              this.setState({ termsExpended: false });
                            }}
                            disabled={!confirmedContact}
                          >
                            Agree
                          </Button>
                        </div>
                      }
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
            }
          </div>}

        <Dialog onClose={() => this.handleClosePaymentModel()} aria-labelledby="customized-title" open={openPaymentModel}
          fullWidth={true}
          maxWidth="md"
        >
          <DialogTitle id="customized-title" onClose={() => this.handleClosePaymentModel()}>
            Make Payment
          </DialogTitle>
          <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
            {paymentDetails &&
              <iframe title="HPayCustomIframe" id='HPayCustomIframe'
                style={{ border: 'none', minWidth: 650, maxWidth: 'auto', minHeight: 590, maxHeight: '80vh' }}
                src={`${paymentDetails.actionUrl}`} />
            }

          </DialogContent>
        </Dialog>

        <Dialog onClose={() => this.setState({ openInvoiceModel: false })}
          aria-labelledby="customized-dialog-title"
          open={openInvoiceModel}>
          <DialogTitle id="customized-dialog" onClose={() => {
            this.getDocStatusById();
            this.setState({ openInvoiceModel: false });
          }}>
            e-Sign Invoice
          </DialogTitle>
          <DialogContent >
            <iframe title="Invoice" style={{ border: 'none' }} width={490} height={620}
              src={this.state.docPublicUrl ? this.state.docPublicUrl : 'https://app.pandadoc.com/document/1dc53e9e2d05fb6c9412006ff242918f51f74e16'} />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  catereaseCP: state.caterease.catereaseCP,
});

const mapDispatchToProps = {
  getFinancialDetails,
  confirmBooking,
  makePayment,
  getPaymentDetailsById,
  makeCoverPaymentCall,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Overview));

