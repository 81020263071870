import React from 'react';
import {
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

function SearchPanel(props) {
  return (
    <TextField
      id="outlined-adornment-password"
      variant="outlined"
      label="Search"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              aria-label="toggle password visibility"

            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default SearchPanel;
//onClick={handleClickShowPassword}
//onMouseDown={handleMouseDownPassword}
//value={values.password}
