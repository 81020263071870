import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Tabs as MaterialTabs,
  Tab,
} from '@material-ui/core';

const styles = theme => ({
  tabStyle: {
    minWidth: 100,
    textTransform: 'unset',
    '&.Mui-selected': {
      background: theme.palette.common.white,
    },
  },
  indicator: {
    display: 'none',
  },
  tabContainer: {
    backgroundColor: theme.palette.light.lightgrey,
    border: `1px solid ${theme.palette.light.darkgrey}`,
  },
});

class Tabs extends Component {
  handleChange = (_event, newValue) => {
    this.props.onChange(newValue);
  }

  render() {
    const {
      classes,
      value,
      tabNames,
    } = this.props;

    return (
      <MaterialTabs value={value} onChange={this.handleChange} classes={{ indicator: classes.indicator }} className={classes.tabContainer}>
        {tabNames.map(name => <Tab label={name} className={classes.tabStyle} key={name} />)}
      </MaterialTabs>
    );
  }
}

export default withStyles(styles)(Tabs);
