import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { CallbackComponent } from 'redux-oidc';
import { regularUserManager } from './userManager';
import FullPageLoader from 'components/FullPageLoader';
import { ResetCatereaseCp } from 'reducers/caterease';
import { ReduxState } from 'reducers/rootReducer';

type Props = RouteComponentProps & ReduxProps & {
}

interface ReduxProps {
  resetCatereaseCp: () => void;
}

class LoggingIn extends Component<Props> {
  successfullyLoggedIn = user => {
    const { history, resetCatereaseCp } = this.props;

    resetCatereaseCp();
    history.push('/');
  }

  render() {
    return (
      <CallbackComponent
        userManager={regularUserManager}
        successCallback={this.successfullyLoggedIn}
        errorCallback={error => {
          this.props.history.push('/');
          console.error(error);
        }}
      >
        <FullPageLoader />
      </CallbackComponent>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
});

const mapDispatchToProps = (dispatch: any) => ({
  resetCatereaseCp: () => dispatch(ResetCatereaseCp()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoggingIn));
