import {
  BOOKING_RECEIVED,
  FETCH_CHITCHAT_MESSAGES_BEGIN,
  FETCH_CHITCHAT_MESSAGES_SUCCESS,
} from 'common/constants';

export type State = {
  booking: any,
  chitChat: {
    threads: any[],
  };
}

const initialState: State = {
  booking: null,
  chitChat: {
    threads: [],
  },
};

function logReduxActions(action) {
  if (window.env.REACT_APP_INSIGHTS_ENABLED === 'true') {
    if (/^redux-oidc/.test(action.type)) {
      const windowAny: any = window;

      if (action.type === 'redux-oidc/USER_FOUND') {
        const user = action.payload.profile.email;
        //const expires = action.payload.expires_at;

        windowAny.appInsights.setAuthenticatedUserContext(user);

        windowAny.appInsights.trackEvent({ name: action.type });
      }
      else {
        if (action.type === 'redux-oidc/SESSION_TERMINATED') {
          windowAny.appInsights.clearAuthenticatedUserContext();
        }
        windowAny.appInsights.trackEvent({ name: action.type });
      }
    }
  }
}

export function api(state = initialState, action) {
  logReduxActions(action);
  console.log(action.type);

  switch (action.type) {
    case BOOKING_RECEIVED: {
      const { booking } = action;

      return { ...state, booking };
    }
    case FETCH_CHITCHAT_MESSAGES_BEGIN: {
      const { chitChat: { threads } } = state;

      return {
        ...state,
        chitChat: {
          threads,
        },
      };
    }
    case FETCH_CHITCHAT_MESSAGES_SUCCESS: {
      const { threads } = action;

      return {
        ...state,
        chitChat: {
          threads,
        },
      };
    }
    default: {
      return state;
    }
  }
}
