import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  AccessTime,
  Place,
} from '@material-ui/icons';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import {
  getLocation,
} from 'actions/events';

const styles = theme => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexGrow: 1,
    padding: 40,
    cursor: 'pointer',
  },
  right: {
    right: 0,
  },
  left: {
    left: 0,
  },
  day: {
    color: theme.palette.blue.blue3,
    fontSize: 38,
  },
  month: {
    fontSize: 14,
  },
  dateColumn: {
    textAlign: 'center',
    paddingRight: 15,
  },
  title: {
    fontSize: 28,
  },
  time: {
    color: theme.palette.grey[500],
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    paddingTop: 6,
    marginLeft: -4,
    paddingBottom: 18,
  },
  icon: {
    height: 19,
    paddingRight: 5,
  },
  padRight: {
    paddingRight: 20,
  },
  description: {
    color: theme.palette.grey[700],
    fontSize: 16,
    maxWidth: 402,
  },
  background: {
    position: 'absolute',
    top: 0,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    height: 403,
    width: 604,
  },
  event1Container: {
    position: 'relative',
    height: 473,
    marginTop: 100,
  },
});

class DashItem extends Component {
  state = {
    location: {},
  };

  componentDidMount() {
    this.props.getLocation(this.props.booking.id).then(location => {
      this.setState({ location });
    });

  }

  render() {
    const {
      classes,
      booking,
    } = this.props;
    const { location } = this.state;

    const startDateTime = DateTime.fromISO(booking.startDateTime); //2020-01-11T16:00:00-05:00
    const endDateTime = DateTime.fromISO(booking.endDateTime); //2020-01-11T16:00:00-05:00

    return (
      <div className={classes.container}>
        <div className={classes.dateColumn}>
          <div className={classes.day}>{startDateTime.toFormat('dd')}</div>
          <div className={classes.month}>{startDateTime.toFormat('MMM').toUpperCase()}</div>
        </div>
        <div>
          <div className={classes.title}>{booking.name}</div>
          <div className={classes.time}>
            <AccessTime className={classes.icon} />
            <span className={classes.padRight}>{startDateTime.toFormat('hh:mma').toLowerCase()} - {endDateTime.toFormat('hh:mma').toLowerCase()}</span>
            <Place className={classes.icon} />
            <span>{location.name}</span>
          </div>
          <div className={classes.description}>
            A wonderful day in the country celebrating John's year of birth. What, what!
            {
              //TODO: add a description field to the booking and wire it in.
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getLocation,
};

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(DashItem));

