import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  container: {
    paddingLeft: 100,
  },
  top: {
    fontSize: 38,
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    height: 38,
    width: 38,
  },
  label: {
    textTransform: 'uppercase',
    color: theme.palette.grey[700],
  },
});

function numberWithCommas(x) {
  return x ? parseInt(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
}

class SpendingItem extends Component {
  render() {
    const {
      classes,
      icon: Icon,
      number,
      label,
    } = this.props;

    return (<div className={classes.container}>
      <div className={classes.top}>
        <Icon className={classes.icon} /> {number < 10 ? '0' + number : numberWithCommas(number)}
      </div>
      <div className={classes.label}>
        {label}
      </div>
    </div>);
  }
}

export default withStyles(styles)(SpendingItem);
