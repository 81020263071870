import callApi from 'common/callApi';
import {
  FETCH_BOOKING_FILES_SUCCESS,
  FETCH_RECENT_BOOKING_FILES_SUCCESS,
} from 'common/constants';

export const fetchBookingFilesSuccess = bookingFile => ({
  type: FETCH_BOOKING_FILES_SUCCESS,
  bookingFile,
});

export const fetchRecentBookingFilesSuccess = bookingFile => ({
  type: FETCH_RECENT_BOOKING_FILES_SUCCESS,
  bookingFile,
});

const getBookingFilesApi = (dispatch, bookingId) => {
  return dispatch(callApi(`v1/files/booking/${bookingId}/sharedFiles`))
    .then(result => result.json());
};

const getRecentBookingFilesApi = dispatch => {
  return dispatch(callApi(`v1/files/booking/recentlySharedFiles`))
    .then(result => result.json());
};

export const getSharedBookingFiles = bookingId => {
  return dispatch => {
    return getBookingFilesApi(dispatch, bookingId)
      .then(files => {
        dispatch(fetchBookingFilesSuccess(files));

        return files;
      }).catch(error => {
        return error;
      });
  };
};

export const getRecentlySharedBookingFiles = () => {
  return dispatch => {
    return getRecentBookingFilesApi(dispatch)
      .then(files => {
        dispatch(fetchRecentBookingFilesSuccess(files));

        return files;
      }).catch(error => {
        return error;
      });
  };
};
