import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 1000,
    background: theme.palette.grey[100],
    width: '100vw',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progress: {
    margin: 40,
  },
});

const FullPageLoader = ({ classes, message = 'Logging in...', height = '100vh' }) => (
  <div className={classes.root} style={{ height }}>
    <div className={classes.loading}>
      <CircularProgress className={classes.progress} />
      {message}
    </div>
  </div>
);

export default withStyles(styles)(FullPageLoader);
