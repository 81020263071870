import React, { Component } from 'react';
import GoodMorning from './GoodMorning';
import TwoBookings from './TwoBookings';
import SpendingSummary from './SpendingSummary';
import FileSummary from './FileSummary';
import { connect } from 'react-redux';
import { getBookings } from 'actions/events';
import { getFinancialSummary } from 'actions/financial';

class Splash extends Component {
  state = {};

  componentDidMount() {
    this.props.getBookings({ pageSize: 0, showCompleted: true }).then(({ meta }) => {
      this.setState({
        totalCompleted: meta.total,
      });
    });

    this.props.getBookings().then(({ data, meta }) => {
      this.setState({
        bookings: data,
        totalOnBooks: meta.total,
      });
    });

    this.props.getFinancialSummary()
      .then(financials => {
        const dollarsSpent = financials.totalWithDiscount;

        this.setState({ dollarsSpent });
      });
  }

  render() {
    const {
      bookings,
      totalOnBooks,
      totalCompleted,
      dollarsSpent,
    } = this.state;

    return (<>
      <GoodMorning />
      <TwoBookings bookings={bookings} total={totalOnBooks} />
      <SpendingSummary totalOnBooks={totalOnBooks} totalCompleted={totalCompleted} dollarsSpent={dollarsSpent} />
      <FileSummary />
    </>);
  }
}

const mapDispatchToProps = {
  getBookings,
  getFinancialSummary,
};

export default connect(undefined, mapDispatchToProps)(Splash);

