import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import classNames from 'classnames';
import { connect } from 'react-redux';
import {
  getImage,
} from 'actions/events';
import DashItem from './DashItem';
import EventDetailsButton from './EventDetailsButton';

const styles = theme => ({
  container: {
    height: 392,
    width: 710,
    backgroundColor: theme.palette.background.paper,
    boxShadow: `0px 10px 50px ${fade(theme.palette.common.black, .1)}`,
    position: 'absolute',
    bottom: 0,
    paddingTop: 40,
    boxSizing: 'border-box',
    display: 'flex',
  },
  right: {
    right: 0,
  },
  left: {
    left: 0,
  },
  day: {
    color: theme.palette.blue.blue3,
    fontSize: 38,
  },
  month: {
    fontSize: 14,
  },
  dateColumn: {
    textAlign: 'center',
    paddingRight: 15,
  },
  title: {
    fontSize: 28,
  },
  time: {
    color: theme.palette.grey[500],
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    paddingTop: 6,
    marginLeft: -4,
    paddingBottom: 18,
  },
  icon: {
    height: 19,
    paddingRight: 5,
  },
  padRight: {
    paddingRight: 20,
  },
  description: {
    color: theme.palette.grey[700],
    fontSize: 16,
    maxWidth: 402,
    paddingBottom: 40,
  },
  button: {
    color: theme.palette.grey[800],
    borderColor: theme.palette.grey[900],
    borderRadius: 0,
    height: 48,
    width: 167,
  },
  background: {
    position: 'absolute',
    top: 0,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    height: 403,
    width: 604,
  },
  event1Container: {
    position: 'relative',
    height: 473,
    marginTop: 100,
  },
});

class DashContainer extends Component {
  state = {
    location: {},
  };

  componentDidMount() {
    this.props.getImage(this.props.booking.id).then(image => {
      this.setState({ image });
    });
  }

  backGroundStyle = () => {
    const {
      image = {},
    } = this.state;

    const {
      alignRight,
    } = this.props;

    if (alignRight) {
      if (image.content) {
        return {
          backgroundImage: `url("data:image/gif;base64,${image.content}")`,
          left: 0,
        };
      }

      return {
        left: 0,
      };
    }

    if (image.content) {
      return {
        backgroundImage: `url("data:image/gif;base64,${image.content}")`,
        right: 0,
      };
    }

    return {
      right: 0,
    };
  }

  render() {
    const { classes, booking, alignRight } = this.props;
    const bgStyle = this.backGroundStyle();

    return (
      <div className={classes.event1Container}>
        <div className={classes.background} style={bgStyle}>&nbsp;</div>

        <div className={classNames(classes.container, alignRight ? classes.right : classes.left)}>
          <DashItem
            booking={booking}
            bottomComponent={EventDetailsButton} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getImage,
};

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(DashContainer));
