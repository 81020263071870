import { ReduxState } from "reducers/rootReducer";

const coverApi = window.env.REACT_APP_COVER_API_URL;
const catereaseApi = window.env.REACT_APP_CATEREASE_API_URL;

//if you want a POST you only need apiCall(url, {body:json})
//if you want a GET you only need apiCall(url)
const callApi = (uri, { body = undefined, contentType = undefined, method = 'GET' } = {}) => (_dispatch, getState) => {
  const state: ReduxState = getState();
  const catereaseUser = !state.oidc.user && state.caterease.catereaseCP;
  const token = catereaseUser
    ? state.caterease.catereaseCP
    : state.oidc.user.access_token;

  const url = `${catereaseUser && !uri.includes('BookingPayment') ? catereaseApi : coverApi}${uri}`;

  if (body) {
    if (!contentType || contentType === 'application/json') {
      body = JSON.stringify(body);
    }
    if (method === 'GET') {
      method = 'POST';
    }
  }

  if (!token) {
    console.error('could not find access_token!');

    return new Promise((_resolve, reject) => reject('could not find access_token!'));
  }
  const headers = catereaseUser && !uri.includes('Payment')
    ? {
      CatereaseCP: token,
      'Content-Type': contentType || 'application/json',
    }
    : {
      Authorization: `Bearer ${token}`,
      'Content-Type': contentType || 'application/json',
    };

  if (uri.includes('Payment')) {
    delete headers.Authorization;
  }

  return callApiWithAuth(url, headers, body, method);
};

const callApiWithAuth = (url, headers, body, method) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method,
      headers,
      body,
    })
      .then(result => {
        if (result.status === 401) {
          reject(result);
          window.location.replace('/Error/Unauthorized');
        }

        resolve(result);
      })
      .catch(result => {
        reject(result);
      });
  });
};

export default callApi;
export { callApi };
