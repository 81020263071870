import { UserState, reducer as oidc } from 'redux-oidc';
import { combineReducers } from 'redux';
import { State as ApiState, api } from 'reducers/api';
import { State as CatereaseState, caterease } from 'reducers/caterease';

const rootReducer = combineReducers({
  oidc,
  api,
  caterease,
});

export type ReduxState = {
  oidc: UserState;
  api: ApiState;
  caterease: CatereaseState;
};

export default rootReducer;
