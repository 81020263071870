import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import SectionHeader from './SectionHeader';
import Files from 'components/Files';

const styles = theme => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    width: 1240,
    paddingTop: 100,
  },
  files: {
    paddingTop: 50,
    maxHeight: 450,
  },
});

class FileSummary extends Component {
  render() {
    const {
      classes,
    } = this.props;

    return (<div className={classes.container}>
      <div className={classes.content}>
        <SectionHeader label="Your Files">
          Find your most recent files below. To access other files please find them in their respective events.
        </SectionHeader>
        <div className={classes.files}>
          <Files />
        </div>
      </div>
    </div>);
  }
}

export default withStyles(styles)(FileSummary);
