import React, { Component } from 'react';
import DashContainer from 'events/DashContainer';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import SectionHeader from './SectionHeader';
import { CircularProgress } from '@material-ui/core';

const styles = theme => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    width: 1240,
    paddingTop: 100,
    paddingBottom: 100,
  },
  upcomingBookings: {
    fontSize: 38,
    color: theme.palette.grey[900],
  },
  topRow: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
  eventsDescription: {
    fontSize: 19,
    paddingLeft: 40,
    color: theme.palette.grey[700],
    borderLeft: `solid 1px ${fade(theme.palette.common.black, .1)}`,
  },
  event1Container: {
    position: 'relative',
    height: 473,
    marginTop: 100,
  },
  event1Bg: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundImage: 'url(event1.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    height: 403,
    width: 604,
  },
  event2Bg: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundImage: 'url(event2.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    height: 403,
    width: 604,
  },
});

class TwoBookings extends Component {
  render() {
    const {
      classes,
      bookings,
      total,
    } = this.props;

    if (!bookings) {
      return (
        <div className={classes.container}>
          <CircularProgress />
        </div>
      );
    }

    let event1 = null;
    let event2 = null;

    if (bookings.length > 0) {
      event1 = bookings[0];

      if (bookings.length >= 2) {
        event2 = bookings[1];
      }
    }

    return (<div className={classes.container}>
      <div className={classes.content}>
        <SectionHeader label="Upcoming Events">
          {total === 0 && <div>You have no upcoming events</div>}
          {total === 1 && <div>Here is your upcoming event</div>}
          {total === 2 && <div>Here are your two upcoming events</div>}
          {total > 2 && <div>Here are two of your upcoming events, to see all your event please <NavLink to="/bookings">
            click here
          </NavLink></div>}
        </SectionHeader>
        {event1 &&
          <DashContainer booking={event1} alignRight={true} />
        }
        {event2 &&
          <DashContainer booking={event2} />
        }
      </div>
    </div>);
  }
}

export default withStyles(styles)(TwoBookings);
