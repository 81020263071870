import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import { Button } from '@material-ui/core';

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 36,
    paddingRight: 110,
    alignItems: 'center',
  },
  salute: {
    fontSize: 38,
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  time: {
    fontSize: 34,
    color: theme.palette.blue.blue1,
  },
  button: {
    fontSize: 28,
    color: theme.palette.blue.blue2,
    borderColor: theme.palette.grey[300],
  },
  topText: {
    paddingTop: 20,
    paddingBottom: 20,
  },
});

class GoodMorning extends Component {

  constructor(props) {
    super(props);
    this.state = {
      now: DateTime.local(),
    };
  }

  componentDidMount() {
    setTimeout(this.tick, 60 * 1000);
  }

  getTimeOfDate() {
    if (this.state.now.hour < 12) {
      return 'Good Morning';
    }
    if (this.state.now.hour < 17) {
      return 'Good Afternoon';
    }

    return 'Good Evening';
  }

  tick = () => {
    this.setState({ now: DateTime.local() });
    setTimeout(this.tick, 60 * 1000);
  }

  render() {
    const {
      classes,
      firstName,
    } = this.props;
    const {
      now,
    } = this.state;

    this.getTimeOfDate();

    return (
      <div className={classes.container}>
        <div className={classes.topText}>
          <div className={classes.salute}>
            {this.getTimeOfDate()} {firstName}!
          </div>
          <div className={classes.time}>
            {now.toFormat('M/d/yyyy | h:mm a')}
          </div>
        </div>
        <Button variant="outlined" classes={{ root: classes.button }}>
          Account
        </Button>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    oidc: {
      user: {
        profile: {
          given_name: firstName,
          family_name: lastName,
        },
      },
    },
  } = state;

  return {
    firstName,
    lastName,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(GoodMorning));
