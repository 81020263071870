export const formatMoney = (price: any) => {
  if (price.toFixed(2) === '-0.00') {
    price = price * -1;

    return price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  } else if (price) {
    return price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  return '0.00';
};
