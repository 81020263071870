import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from 'serviceWorker';

/* ------- Froala ------- */
// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

//froala plugins... colors plugin
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/css/plugins/colors.min.css';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
// Require Font Awesome. (required from some of the buttons in froala)
import 'font-awesome/css/font-awesome.css';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
