import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { store, persistor } from 'common/store';
import { ThemeProvider } from '@material-ui/styles';
import { OidcProvider, loadUser } from 'redux-oidc';
import { regularUserManager } from './auth/userManager';
import Authenticate from './auth/Authenticate';
import defaultTheme from './common/theme';
import { BrowserRouter } from 'react-router-dom';
import './App.css';

loadUser(store, regularUserManager);

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={defaultTheme}>
        <OidcProvider store={store} userManager={regularUserManager}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <Authenticate />
            </BrowserRouter>
          </PersistGate>
        </OidcProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
