import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  root: {
    border: `1px solid ${theme.palette.light.purple1}`,
    borderRadius: '4px',
    fontSize: '13px',
    minWidth: '99px',
    minHeight: '32px',
    padding: '0 45px',
    textTransform: 'initial',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.common.black,
    fontWeight: 400,
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.common.black,
    },
  },
  disabled: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[200],
  },
});

class PrimaryButton extends Component {
  render() {
    const { classes, children, ...rest } = this.props;

    return (
      <Button {...rest} classes={{ root: classes.root, disabled: classes.disabled }}>
        {children}
      </Button>
    );
  }
}

export default withStyles(styles)(PrimaryButton);
