import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Nav from './Nav';
import {
  getImage,
} from 'actions/events';
import { connect } from 'react-redux';

const styles = theme => ({
  container: {
    backgroundColor: theme.palette.grey[800],
  },
});

class Header extends Component {

  componentDidMount() {
    this.loadImage();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.booking !== this.props.booking) {
      this.loadImage();
    }
  }

  loadImage = () => {
    if (!this.props.booking) {
      return;
    }
    this.props.getImage(this.props.booking.id).then(image => {
      if (image.content) {
        const styleWithImage = {
          backgroundImage: `url("data:image/gif;base64,${image.content}")`,
        };

        this.setState({ styleWithImage });
      }

    });
  }

  render() {
    //TODO inject some parts of the booking into template
    const {
      classes,
    } = this.props;

    return (
      <div className={classes.container} >
        <Nav />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getImage,
};

const mapStateToProps = state => {
  const { booking } = state.api;

  return { booking };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Header));
